<template>
	<div class="container">
			<div class="head">Impressum</div>
		<div class="impr">

			<div class="cont">
			<div>Für den Inhalt verantwortlich:</div>
				<div>Viridarium Gartengestaltung</div>
				<div>Fast & Pauritsch OG</div>
				<div>Grenzweg 8</div>
				<div>8401 Kalsdorf</div>
				<div class="kont">Kontakt</div>
				<div>Marianne Fast: +43 664 / 790 37 63</div>
				<div>Peter Pauritsch: +43 676 / 300 95 46</div>
				<div>Rudi Fast: +43 699 / 100 548 98</div>
				<div><a href="mailto:marianne.fast@viridarium.at">E-mail: marianne.fast@viridarium.at</a></div>
				<div>Eingetragen im österreichischen Firmenverzeichnis.</div>
			</div>

		</div>
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'Impressum',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.container {
	height: 100%;
}

.head {
	text-align: center;
	border-radius: 4px;
	color: gray;
	font-size: 1.2rem;
	padding: 0.7rem;
	margin-bottom: 1rem;
	font-weight: bold;
	// border-bottom: 1px solid silver;
}

div.impr {
	text-align: left;
	width: 41rem;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	@media (max-width: 600px){
		width: auto;
}

	& div.cont {
		// border: 1px solid silver;
		border-radius: 8px;
		padding: 2.4rem;
		// box-shadow: 1px 1px 2px #999;
		border: 1px solid #BFBFBF;

	@media (max-width: 600px){
		border: none;
		padding: 0 2.4rem;
}		

		&>:nth-child(1){
			// border-top: 1px solid silver;
			color: gray;
			margin-bottom: 0.4rem;
		}			
		&>:nth-child(2){
			font-family: f2;
			font-size: 1.3rem;
			font-weight: bold;
			color: var(--c13);
			margin-bottom: 0.2rem;
		}
		&>:last-child{
			// margin: 0.6rem 0;
			border-top: 1px solid silver;
			// // border-left: 10px solid var(--c12);
			// // background-color: #ddd;
			font-size: .9rem;
			color: gray;
			// padding: 0.2rem 0;


		}

		&	a {
			text-decoration: none;
			color: var(--c11);
		}
		& .kont {
			background-color: var(--c12);
			padding: 0 0.4rem;
			margin: 0.2rem 0;
			color: white;
			box-shadow: 1px 1px 1px #AFAFAF;


		}
	}

}


</style>
